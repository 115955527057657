.login-box {
    width: 800px;
    height: 600px;
    background-color: var(--neutral-color-0);
    position: fixed;
    left: calc(50vw - 400px);
    top: calc(50vh - 300px);
    box-shadow: 1px 4px 22px -8px var(--neutral-color-4);
    border-radius: 10px;
    display: flex;
    overflow: hidden;
}

.login-box-right {
    width: 400px;
    background-color: var(--primary-color-b);
    background-image: url(../assets/main_logo_large.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 190px auto;
}

.login-box-left {
    width: 400px;
}

.login-box-title {
    font-size: 32px;
    font-weight: 900;
    text-align: center;
    line-height: 40px;
    margin-top: 120px;
    margin-bottom: 30px;
    color: var(--neutral-color-h);
}

.login-box-title-small {
    font-size: 16px;
    color: var(--neutral-color-7);
    font-weight: 500;
}

.login-box-form {
    padding: 0px 50px;
}

.login-box-label {
    display: block;
    line-height: 30px;
    font-size: 14px;
    margin-top: 10px;
    color: var(--neutral-color-h);
    font-weight: 600;
}

.login-box input {
    width: 280px;
    border: 2px solid var(--neutral-color-3);
    padding: 0px 12px;
    height: 35px;
    border-radius: 4px;
    outline: none;
    color: var(--neutral-color-h);
}

.login-box input:focus {
    border: 2px solid var(--primary-color);
}

.login-box input:disabled {
    opacity: 0.5;
}

.login-box button {
    margin-top: 10px;
    background-color: var(--primary-color-b);
    border: none;
    border-radius: 4px;
    height: 40px;
    width: 120px;
    color: var(--neutral-color-0);
    cursor: pointer;
}

.login-box button:active {
    background-color: var(--primary-color-c);
}

.login-box button:disabled {
    opacity: 0.8;
    cursor: default;
}

.login-box-error {
    color: var(--semantic-error-color);
    padding-top: 10px;
    font-size: 14px;
}