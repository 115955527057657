.confirm-modal,
.confirm-modal * {
    user-select: none;
}

.confirm-modal-backdrop {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 999;
    background-color: #1115;
    animation-name: backdrop-animation;
    animation-duration: 0.3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    left: 0px;
    top: 0px;
}

.confirm-modal-container {
    background-color: var(--neutral-color-0);
    width: 450px;
    position: fixed;
    z-index: 1000;
    left: calc(50vw - 225px + 125px);
    border-radius: 8px;
    box-shadow: 0px 4px 18px var(--neutral-color-8);
    display: block;
    animation-name: container-animation;
    animation-duration: 0.3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes backdrop-animation {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes container-animation {
    0% {
        opacity: 0;
        top: 40px;
    }

    100% {
        opacity: 1;
        top: 80px;
    }
}

.confirm-modal-container-header {
    height: 35px;
    border-bottom: 1px solid var(--secondary-color-2);
}

.confirm-modal-close {
    color: var(--secondary-color-4);
    font-size: 20px;
    font-weight: 600;
    line-height: 35px;
    width: 35px;
    float: right;
    text-align: center;
    cursor: pointer;
}

.confirm-modal.closed .confirm-modal-backdrop {
    animation-name: backdrop-closed-animation;
}

.confirm-modal.closed .confirm-modal-container {
    animation-name: container-closed-animation;
}

@keyframes backdrop-closed-animation {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes container-closed-animation {
    100% {
        opacity: 0;
        top: 40px;
    }

    0% {
        opacity: 1;
        top: 80px;
    }
}

.confirm-modal-container-message {
    padding: 30px 20px;
    text-align: center;
}

.confirm-modal-container-buttons {
    padding: 20px 115px;
    display: flex;
}

.confirm-modal-button {
    width: 100px;
    line-height: 40px;
    margin: 5px;
    border-radius: 6px;
    text-align: center;
    font-size: 13px;
    color: var(--neutral-color-2);
    border: none;
    outline: none;
    cursor: pointer;
    transition: all 0.2s;
}

.confirm-modal-button.action {
    background-color: var(--primary-color-b);
}

.confirm-modal-button.action:hover {
    background-color: var(--primary-color-a);
}

.confirm-modal-button.action:active {
    background-color: var(--primary-color-c);
}

.confirm-modal-button.cancel {
    background-color: var(--secondary-color-d);
}

.confirm-modal-button.cancel:hover {
    background-color: var(--secondary-color-c);
}

.confirm-modal-button.cancel:active {
    background-color: var(--secondary-color-e);
}

.confirm-modal.locked .confirm-modal-button {
    filter: grayscale(100%);
    opacity: 0.6;
}