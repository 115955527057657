.ribbon {
    height: 60px;
    box-shadow: 0px 0px 14px var(--neutral-color-2);
    background-color: var(--neutral-color-0);
}

.ribbon-head {
    outline: none;
    border: none;
    height: 60px;
    line-height: 60px;
    background-repeat: no-repeat;
    background-size: 25px auto;
    background-position: 17.5px 17.5px;
    padding-left: 55px;
    padding-right: 20px;
    font-weight: 600;
    font-size: 18px;
    background-color: transparent;
    text-align: left;
    opacity: 0.8;
    transition: all 0.2s;
    color: var(--neutral-color-k);
}

.ribbon-head[data-icon="route-back"] {
    cursor: pointer;
    background-size: 20px auto;
    background-position: 20px 20px;
    padding-left: 50px;
}

.ribbon-head[data-icon="route-back"]:hover {
    opacity: 0.85;
    background-position: 16px 20px;
}

.ribbon-exit {
    background-color: var(--secondary-color-d);
    color: var(--neutral-color-0);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 13px;
    transition: all 0.2s;
    float: right;
    height: 35px;
    margin: 12.5px;
    padding: 0px 15px 0px 35px;
    background-image: url('data:image/svg+xml,<svg version="1.1" viewBox="0 0 20 20" width="200" height="200" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="fill: rgb(255, 255, 255); opacity: 1; transform: rotate(0deg) scale(0.7, 0.7);"><g><path d="M13 3v2h2v10h-2v2h4V3h-4zm0 8V9H5.4l4.3-4.3-1.4-1.4L1.6 10l6.7 6.7 1.4-1.4L5.4 11H13z"></path></g></svg>');
    background-position: 5px 5px;
    background-size: 25px auto;
    background-repeat: no-repeat;
}

.ribbon-exit:hover {
    background-color: var(--secondary-color-c);
}

.ribbon-exit:active {
    background-color: var(--secondary-color-e);
}