[data-theme="classic"] {
    /* primary */
    --primary-color: #ff0000;
    --primary-color-0: #ffffff;
    --primary-color-1: #ffe6e6;
    --primary-color-2: #ffcccc;
    --primary-color-3: #ffb3b3;
    --primary-color-4: #ff9999;
    --primary-color-5: #ff8080;
    --primary-color-6: #ff6666;
    --primary-color-7: #ff4d4d;
    --primary-color-8: #ff3333;
    --primary-color-9: #ff1a1a;
    --primary-color-a: #ff0000;
    --primary-color-b: #e60000;
    --primary-color-c: #cc0000;
    --primary-color-d: #b30000;
    --primary-color-e: #990000;
    --primary-color-f: #800000;
    --primary-color-g: #660000;
    --primary-color-h: #4d0000;
    --primary-color-i: #330000;
    --primary-color-j: #1a0000;
    --primary-color-k: #000000;
    /* secondary */
    --secondary-color: #4080bf;
    --secondary-color-0: #ffffff;
    --secondary-color-1: #ecf2f9;
    --secondary-color-2: #d9e6f2;
    --secondary-color-3: #c6d9ec;
    --secondary-color-4: #b3cce6;
    --secondary-color-5: #9fbfdf;
    --secondary-color-6: #8cb3d9;
    --secondary-color-7: #79a6d2;
    --secondary-color-8: #6699cc;
    --secondary-color-9: #538cc6;
    --secondary-color-a: #4080bf;
    --secondary-color-b: #3973ac;
    --secondary-color-c: #336699;
    --secondary-color-d: #2d5986;
    --secondary-color-e: #264d73;
    --secondary-color-f: #204060;
    --secondary-color-g: #19334d;
    --secondary-color-h: #132639;
    --secondary-color-i: #0d1a26;
    --secondary-color-j: #060d13;
    --secondary-color-k: #000000;
    /* neutral */
    --neutral-color: #808080;
    --neutral-color-0: #ffffff;
    --neutral-color-1: #f2f2f2;
    --neutral-color-2: #e6e6e6;
    --neutral-color-3: #d9d9d9;
    --neutral-color-4: #cccccc;
    --neutral-color-5: #bfbfbf;
    --neutral-color-6: #b3b3b3;
    --neutral-color-7: #a6a6a6;
    --neutral-color-8: #999999;
    --neutral-color-9: #8c8c8c;
    --neutral-color-a: #808080;
    --neutral-color-b: #737373;
    --neutral-color-c: #666666;
    --neutral-color-d: #595959;
    --neutral-color-e: #4d4d4d;
    --neutral-color-f: #404040;
    --neutral-color-g: #333333;
    --neutral-color-h: #262626;
    --neutral-color-i: #1a1a1a;
    --neutral-color-j: #0d0d0d;
    --neutral-color-k: #000000;
    /* semantic */
    --semantic-success-color: #22b170;
    --semantic-error-color: #ff0000;
    --semantic-warning-color: #fa9a3c;
}