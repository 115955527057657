.side-menu {
    width: 250px;
    height: 100vh;
    position: fixed;
    background-color: var(--secondary-color-d);
    user-select: none;
}

.side-menu-logo {
    font-size: 18px;
    background-color: var(--secondary-color-f);
    margin-bottom: 20px;
    background-image: url(../assets/main_logo_large.png);
    background-size: 30px auto;
    background-repeat: no-repeat;
    background-position: 20px center;
    padding: 12px 12px 12px 64px;
}

.side-menu-logo-title {
    line-height: 25px;
    color: var(--secondary-color-1);
    font-weight: 600;
}

.side-menu-logo-sub-title {
    line-height: 13px;
    font-size: 12px;
    color: var(--secondary-color-5);
}

.side-menu-item {
    line-height: 38px;
    padding: 0px 18px 0px 45px;
    cursor: pointer;
    color: var(--neutral-color-1);
    opacity: 0.85;
    transition: all 0.2s;
    font-size: 15px;
    display: block;
    text-decoration: none;
    background-repeat: no-repeat;
    background-size: 20px auto;
    background-position: 15.5px 9px;
}

.side-menu-item:hover {
    background-color: var(--secondary-color-e);
    opacity: 0.85;
}

.side-menu-item[data-active="true"] {
    background-color: var(--secondary-color-f);
    opacity: 1;
    box-shadow: inset 5px 0px 0px var(--secondary-color-0);
}