.table-modal {
    border-radius: 5px;
    border: 1px solid var(--secondary-color-2);
    overflow: hidden;
    box-shadow: 0px 2px 15px var(--neutral-color-1);
    background-color: var(--neutral-color-0);
}

.table-modal.loading {
    opacity: 0.7;
    filter: grayscale(90%);
}

.table-modal.loading * {
    cursor: default !important;
}

/* ================================= header ================================= */

.table-modal-header {
    padding: 15px;
    border-bottom: 1px solid var(--secondary-color-3);
    display: flex;
    height: 37px;
}

.table-modal-search-input {
    padding: 0px 8px;
    height: 35px;
    border-radius: 5px;
    border: 1px solid var(--secondary-color-3);
    color: var(--neutral-color-d);
    outline: none;
    width: 300px;
    vertical-align: middle;
}

.table-modal-create-button {
    background-color: var(--secondary-color-d);
    color: var(--neutral-color-0);
    border: none;
    height: 37px;
    min-width: 65px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 13px;
    transition: all 0.2s;
    padding: 0px 15px 0px 30px;
    float: right;
    background-image: url('data:image/svg+xml,<svg version="1.1" viewBox="0 0 16 16" width="200" height="200" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="fill: rgb(255, 255, 255); opacity: 1; transform: rotate(0deg) scale(0.7, 0.7);"><g><path d="M8 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2H9v6a1 1 0 1 1-2 0V9H1a1 1 0 0 1 0-2h6V1a1 1 0 0 1 1-1z"></path></g></svg>');
    background-position: 7px 7px;
    background-size: 20px auto;
    background-repeat: no-repeat;
    margin-left: calc(100% - 510px);
}

.table-modal-create-button:hover {
    background-color: var(--secondary-color-c);
}

.table-modal-create-button:active {
    background-color: var(--secondary-color-e);
}

.table-modal-search-button {
    background-color: var(--secondary-color-d);
    color: var(--neutral-color-0);
    cursor: pointer;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 25px auto;
    width: 37px;
    height: 37px;
    border: none;
    outline: none;
    border-radius: 5px;
    vertical-align: middle;
    margin-left: 10px;
}

.table-modal-search-button:hover {
    background-color: var(--secondary-color-c);
}

.table-modal-search-button:active {
    background-color: var(--secondary-color-e);
}

.table-modal-search-button.ready {
    background-image: url('data:image/svg+xml,<svg version="1.1" viewBox="0 0 24 24" width="200" height="200" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="fill: rgb(255, 255, 255); opacity: 1; transform: rotate(0deg) scale(0.7, 0.7);"><g><path d="M20.71,19.29l-3.4-3.39A7.92,7.92,0,0,0,19,11a8,8,0,1,0-8,8,7.92,7.92,0,0,0,4.9-1.69l3.39,3.4a1,1,0,0,0,1.42,0A1,1,0,0,0,20.71,19.29ZM5,11a6,6,0,1,1,6,6A6,6,0,0,1,5,11Z"></path></g></svg>');
}

.table-modal-search-button.clear {
    background-image: url('data:image/svg+xml,<svg version="1.1" viewBox="0 0 24 24" width="200" height="200" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="fill: rgb(255, 255, 255); opacity: 1; transform: rotate(0deg) scale(0.7, 0.7);"><g><path d="M21,6H16V4.33A2.42,2.42,0,0,0,13.5,2h-3A2.42,2.42,0,0,0,8,4.33V6H3A1,1,0,0,0,3,8H4V19a3,3,0,0,0,3,3H17a3,3,0,0,0,3-3V8h1a1,1,0,0,0,0-2ZM10,16a1,1,0,0,1-2,0V12a1,1,0,0,1,2,0ZM10,4.33c0-.16.21-.33.5-.33h3c.29,0,.5.17.5.33V6H10ZM16,16a1,1,0,0,1-2,0V12a1,1,0,0,1,2,0Z"></path></g></svg>');
}

/* ================================= container ================================= */

.table-modal-body {
    overflow-x: auto;
}

.table-modal-body::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

.table-modal-body::-webkit-scrollbar-track {
    background-color: var(--secondary-color-1);
}

.table-modal-body::-webkit-scrollbar-thumb {
    background-color: var(--secondary-color-d);
}

.table-modal-container {
    width: 100%;
}

.table-modal-container-head {
    background-color: var(--secondary-color-1);
    height: 50px;
    user-select: none;
}

.table-modal-container-head th {
    padding: 12px 15px;
    color: var(--secondary-color-7);
    font-weight: 500;
    font-size: 14px;
    text-transform: uppercase;
    box-shadow: inset 0px -1px 0px var(--secondary-color-3);
}

.table-modal-container-head .sort {
    cursor: pointer;
    transition: background 0.2s;
    user-select: none;
    white-space: nowrap;
}

.table-modal-container-head .sort:hover {
    background-color: var(--secondary-color-2);
}


.table-modal-container-head span::after {
    content: '..';
    font-family: monospace;
    font-size: 18px;
    color: transparent;
}

.table-modal-container-head span.ASC::after {
    content: ' ▲';
    color: inherit;
}

.table-modal-container-head span.DESC::after {
    content: ' ▼';
    color: inherit;
}

.table-modal-container-body tr {
    background-color: var(--neutral-color-0);
    color: var(--neutral-color-k);
    transition: background 0.1s;
    height: 65px;
}

.table-modal-container-body tr:hover {
    background-color: #00000004;
}

.table-modal-container-body td {
    padding: 14px 15px;
    font-size: 14px;
    box-shadow: inset 0px -1px 0px var(--secondary-color-2);
}

.table-modal-container-body td img {
    border-radius: 5px;
}

.table-modal-container-body tr.empty,
.table-modal-container-body tr.empty:hover {
    background-color: var(--neutral-color-0) !important;
    color: var(--neutral-color-a);
}

/* ================================= footer ================================= */

.table-modal-footer {
    display: flex;
    padding: 15px;
}

.table-modal-footer-button {
    width: 35px;
    height: 35px;
    line-height: 34px;
    margin-right: 5px;
    padding: 0px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 20px auto;
    border: 1px solid var(--secondary-color-d);
    border-radius: 5px;
    background-color: var(--neutral-color-0);
    cursor: pointer;
    color: var(--secondary-color-d);
}

.table-modal-footer-button.current {
    background-color: var(--secondary-color-d);
    color: var(--neutral-color-0);
    cursor: default;
}

.table-modal-footer-button.jump {
    border-color: var(--neutral-color-a);
    cursor: pointer;
}

.table-modal-footer-button.jump:disabled {
    opacity: 0.4;
    cursor: default;
}

.table-modal-footer-button.previous {
    background-image: url('data:image/svg+xml,<svg version="1.1" viewBox="0 0 20 20" width="200" height="200" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="fill: rgb(0, 0, 0); opacity: 1; transform: rotate(0deg) scale(0.7, 0.7);"><g><path d="M14 5l-5 5 5 5-1 2-7-7 7-7z"></path></g></svg>');
}

.table-modal-footer-button.next {
    background-image: url('data:image/svg+xml,<svg version="1.1" viewBox="0 0 20 20" width="200" height="200" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="fill: rgb(0, 0, 0); opacity: 1; transform: rotate(0deg) scale(0.7, 0.7);"><g><path d="M6 15l5-5-5-5 1-2 7 7-7 7z"></path></g></svg>');
}

.table-modal-footer-button.summary {
    border-color: transparent;
    cursor: default;
    background-color: transparent;
}

/* ================================= control button ================================= */

.table-modal-control-button {
    background-color: var(--secondary-color-d);
    border: none;
    height: 35px;
    min-width: 65px;
    border-radius: 5px;
    cursor: pointer;
    color: var(--neutral-color-0);
    font-size: 13px;
    transition: all 0.2s;
    user-select: none;
}

.table-modal-control-button:hover {
    background-color: var(--secondary-color-c);
}

.table-modal-control-button:active {
    background-color: var(--secondary-color-e);
}

/* ================================= control switch ================================= */

.table-modal-control-switch {
    padding: 0px 10px 0px 40px;
    display: inline-block;
    margin: 5px;
    font-size: 13px;
    font-weight: 500;
    height: 30px;
    line-height: 30px;
    cursor: pointer;
    border: none;
    background-color: transparent;
    background-size: 30px auto;
    background-repeat: no-repeat;
    transition: all 0.1s ease-in-out;
    border-radius: 8px;
    overflow: hidden;
    vertical-align: top;
}

.table-modal-control-switch.off {
    background-position: 3px 5px, 5px 0px;
    background-image: url('data:image/svg+xml,<svg version="1.1" viewBox="0 0 16 16" width="200" height="200" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="fill: rgb(255, 255, 255); opacity: 1; transform: rotate(0deg) scale(0.9, 0.9);"><g><path d="m6 9a4 4 0 1 1 0-8 4 4 0 0 1 0 8z"></path></g></svg>'), url('data:image/svg+xml,<svg version="1.1" viewBox="0 0 16 16" width="200" height="200" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="fill: rgb(162, 162, 162); opacity: 1; transform: rotate(0deg) scale(0.9, 0.9);"><g><path d="M5 3a5 5 0 0 0 0 10h6a5 5 0 0 0 0-10H5z"></path></g></svg>');
}

.table-modal-control-switch.on {
    background-position: 14px 5px, 5px 0px;
    background-image: url('data:image/svg+xml,<svg version="1.1" viewBox="0 0 16 16" width="200" height="200" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="fill: rgb(255, 255, 255); opacity: 1; transform: rotate(0deg) scale(0.9, 0.9);"><g><path d="m6 9a4 4 0 1 1 0-8 4 4 0 0 1 0 8z"></path></g></svg>'), url('data:image/svg+xml,<svg version="1.1" viewBox="0 0 16 16" width="200" height="200" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="fill: rgb(45, 89, 134); opacity: 1; transform: rotate(0deg) scale(0.9, 0.9);"><g><path d="M5 3a5 5 0 0 0 0 10h6a5 5 0 0 0 0-10H5z"></path></g></svg>');
}