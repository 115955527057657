.form-modal-item,
.form-modal-list {
  display: inline-block;
  width: calc(50vw - 190px);
  margin: 10px;
  overflow: hidden;
  vertical-align: top;
}

.form-modal-list {
  display: block;
  width: auto;
  border-radius: 4px;
}

.form-modal-item-input {
  display: flex;
  padding: 4px;
}

.form-modal-item-label {
  line-height: 30px;
  font-size: 14px;
  color: var(--neutral-color-f);
  user-select: none;
  padding: 0px 4px;
}

.form-modal-item-control {
  width: calc(100% - 22px);
  line-height: 30px;
  border: 1px solid var(--neutral-color-3);
  appearance: none;
  outline: none;
  border-radius: 4px;
  padding: 5px 10px;
  font-size: 14px;
  transition-timing-function: ease-in-out;
  transition-duration: 0.15s;
  transition-property: border-color, box-shadow, background;
  color: var(--neutral-color-k);
}

.form-modal-item-control:focus {
  border-color: var(--secondary-color-8);
  outline: 4px solid var(--secondary-color-2);
}

.form-modal.disable .form-modal-item-control {
  background-color: var(--neutral-color-0);
  color: var(--neutral-color-e);
  opacity: 1;
  cursor: default;
}

/* ================================= textarea ================================= */

.form-modal-item-control.textarea {
  height: 30px;
  resize: vertical;
}

/* ================================= dropdown ================================= */

.form-modal-item-control.dropdown {
  width: calc(100% - 0px);
  background-image: url('data:image/svg+xml,<svg version="1.1" viewBox="0 0 24 24" width="200" height="200" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="fill: rgb(0, 0, 0); opacity: 0.7; transform: rotate(0deg) scale(1, 1);"><g><path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z"></path></g></svg>');
  background-size: auto 20px;
  background-position: calc(100% - 10px) center;
  background-repeat: no-repeat;
  user-select: none;
}

/* ================================= checkbox ================================= */

.form-modal-item-control.checkbox {
  width: 15px;
  height: 15px;
  padding: 0px;
  vertical-align: middle;
  margin-left: 30px;
  margin-bottom: 2px;
  background-color: var(--neutral-color-0);
}

.form-modal-item-control.checkbox:checked {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3 6-6'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: var(--secondary-color-a);
  border-color: var(--secondary-color-a);
}

.form-modal-item-input.checkbox {
  padding: 25px 0px;
}

/* ================================= switch ================================= */

.form-modal-item-input.switch {
  padding: 20px 0px;
}

.form-modal-item-input.switch > label {
  display: flex;
  line-height: 38px;
}

.form-modal-item-control.switch {
  padding: 0px 2px 0px 24px;
  display: inline-block;
  margin: 5px;
  font-size: 13px;
  font-weight: 500;
  height: 18px;
  line-height: 30px;
  cursor: pointer;
  background-color: transparent;
  background-size: 30px auto;
  background-repeat: no-repeat;
  transition: all 0.1s ease-in-out;
  border-radius: 8px;
  overflow: hidden;
  vertical-align: top;
  margin-left: 30px;
  margin-top: 11px;
}

.form-modal-item-control.switch {
  background-image: url('data:image/svg+xml,<svg version="1.1" viewBox="0 0 16 16" width="200" height="200" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="fill: rgb(255, 255, 255); opacity: 1; transform: rotate(0deg) scale(0.9, 0.9);"><g><path d="m6 9a4 4 0 1 1 0-8 4 4 0 0 1 0 8z"></path></g></svg>'),
    url('data:image/svg+xml,<svg version="1.1" viewBox="0 0 16 16" width="200" height="200" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="fill: rgb(162, 162, 162); opacity: 0.5; transform: rotate(0deg) scale(0.9, 0.9);"><g><path d="M5 3a5 5 0 0 0 0 10h6a5 5 0 0 0 0-10H5z"></path></g></svg>');
  background-position: -4px -2px, -2px -7px;
  border-color: var(--neutral-color-5);
}

.form-modal-item-control.switch:checked {
  background-image: url('data:image/svg+xml,<svg version="1.1" viewBox="0 0 16 16" width="200" height="200" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="fill: rgb(255, 255, 255); opacity: 1; transform: rotate(0deg) scale(0.9, 0.9);"><g><path d="m6 9a4 4 0 1 1 0-8 4 4 0 0 1 0 8z"></path></g></svg>'),
    url('data:image/svg+xml,<svg version="1.1" viewBox="0 0 16 16" width="200" height="200" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="fill: rgb(64, 128, 191); opacity: 1; transform: rotate(0deg) scale(0.9, 0.9);"><g><path d="M5 3a5 5 0 0 0 0 10h6a5 5 0 0 0 0-10H5z"></path></g></svg>');
  background-position: 7px -2px, -2px -7px;
  border-color: var(--secondary-color-a);
}

/* =============================== unit label =============================== */

.form-modal-item-control[data-unit] {
  width: calc(100% - 50px - 13px);
  border-radius: 0px 4px 4px 0px;
  height: 30px;
}

.form-modal-item-unit[hidden] {
  display: none;
}

.form-modal-item-unit {
  display: inline-block;
  width: 40px;
  text-align: center;
  font-size: 13px;
  border: 1px solid var(--neutral-color-3);
  border-right: none;
  border-radius: 4px 0px 0px 4px;
  line-height: 40px;
  color: var(--neutral-color-b);
  background-color: var(--neutral-color-1);
  font-weight: 500;
  user-select: none;
}

/* ================================= separator ================================= */

.form-modal-line {
  margin: 10px;
}

.form-modal-line > hr {
  border-width: 0px;
  border-bottom: 1px solid var(--secondary-color-2);
  background-color: var(--secondary-color-2);
}

/* ============================= array and objects ============================= */

.form-modal-list > .form-modal-item-input {
  display: block;
}

.form-modal-list .form-modal-item {
  width: 200px;
}

.form-modal-list {
  background-color: var(--neutral-color-0);
  box-shadow: 0px 0px 12px var(--neutral-color-2);
  width: auto;
  display: inline-block;
  border-radius: 5px;
}

.form-modal-list > .form-modal-item-label {
  background-color: var(--secondary-color-c);
  line-height: 60px;
  padding: 0px 10px 0px 20px;
  color: var(--neutral-color-0);
}

.form-modal-list.array > .form-modal-item-input {
  padding: 0px;
}

.form-modal-item-row {
  border-bottom: 1px solid var(--neutral-color-2);
}

.form-modal-item-row:last-child {
  border: none;
}

/* ================================= array buttons ================================= */

.form-modal-array-button {
  width: 40px;
  height: 40px;
  border: none;
  cursor: pointer;
  transition: background 0.2s;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px auto;
  border-radius: 50px;
}

.form-modal-array-button.add {
  float: right;
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 10px;
  background-color: var(--secondary-color-8);
  background-image: url('data:image/svg+xml,<svg version="1.1" viewBox="0 0 24 24" width="200" height="200" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="fill: rgb(255, 255, 255); opacity: 0.8; transform: rotate(0deg) scale(1, 1);"><g><path d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z"></path></g></svg>');
}

.form-modal-array-button.add:hover {
  background-color: var(--secondary-color-7);
}

.form-modal-array-button.add:active {
  background-color: var(--secondary-color-9);
}

.form-modal-array-button.remove {
  display: inline-block;
  vertical-align: bottom;
  margin-bottom: 15px;
  margin-right: 20px;
  margin-left: 10px;
  background-color: var(--neutral-color-3);
  background-image: url('data:image/svg+xml,<svg version="1.1" viewBox="0 0 24 24" width="200" height="200" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="fill: rgb(255, 255, 255); opacity: 1; transform: rotate(0deg) scale(1, 1);"><g><path d="M5 11h14v2H5z"></path></g></svg>');
}

.form-modal-array-button.remove:hover {
  background-color: var(--neutral-color-2);
}

.form-modal-array-button.remove:active {
  background-color: var(--neutral-color-4);
}

.form-modal.disable .form-modal-array-button {
  display: none;
}

/* ====================================== image ===================================== */

.form-modal-item-input.image {
  padding: 0px;
  margin: 4px;
  height: 192px;
  width: 341px;
  border-radius: 4px;
  overflow: hidden;
  transition-duration: 0.2s;
  transition-property: color, opacity, background;
  user-select: none;
  display: block;
}

.form-modal-item-input.image > input {
  display: none;
}

.form-modal-item-input.image > .image-inner {
  height: 112px;
  width: 341px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  display: block;
  padding-top: 80px;
  color: transparent;
  cursor: pointer;
  transition-duration: 0.2s;
  transition-property: color, opacity;
}

.form-modal-item-input.image.loading > .image-inner,
.form-modal-item-input.image.uploading > .image-inner {
  cursor: default;
}

.form-modal-item-input.image.loading,
.form-modal-item-input.image.uploading {
  background-image: url(../assets/spinner.svg);
  background-position: center;
  background-size: 25px auto;
  background-repeat: no-repeat;
  background-color: var(--neutral-color-1);
}

.form-modal-item-input.image.ready > .image-inner:hover {
  color: var(--neutral-color-0);
  /* opacity: 0.85; */
}

.form-modal-item-input.image.ready:hover {
  background-color: var(--neutral-color-k);
}

.form-modal-item-input.image.empty {
  outline: 1px dashed var(--neutral-color-5);
  background-color: var(--neutral-color-1);
}

.form-modal-item-input.image.empty > .image-inner {
  color: var(--neutral-color-8);
}

.form-modal.disable .image-inner {
  cursor: default;
  color: transparent !important;
  opacity: 1 !important;
}

/* ======================================= map ====================================== */

.form-modal-item-input.map {
  width: calc(50vw - 200px) !important;
  height: 350px !important;
  border-radius: 4px;
  overflow: hidden;
  padding: 0px;
  margin: 4px;
  background-color: var(--neutral-color-3);
  color: transparent;
  overflow: hidden;
  background-image: url('data:image/svg+xml,<svg version="1.1" viewBox="0 0 1200 1200" width="200" height="200" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="fill: rgb(0, 0, 0); opacity: 0.2; transform: rotate(0deg) scale(1, 1);"><g><path d="M600,0C350.178,0,147.656,202.521,147.656,452.344c0,83.547,16.353,169.837,63.281,232.031L600,1200l389.062-515.625c42.625-56.49,63.281-156.356,63.281-232.031C1052.344,202.521,849.822,0,600,0z M600,261.987c105.116,0,190.356,85.241,190.356,190.356C790.356,557.46,705.116,642.7,600,642.7s-190.356-85.24-190.356-190.356S494.884,261.987,600,261.987z"></path></g></svg>');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 120px auto;
}

.form-modal-item-input.map > div > div {
  width: calc(50vw - 200px) !important;
  height: 350px !important;
  border-radius: 4px;
  overflow: hidden;
}

/* ================================= html ================================= */

.form-modal-item-control.html {
  height: 180px;
  background-color: var(--neutral-color-0);
  resize: vertical;
  overflow-y: auto;
}

/* ================================= submit buttons ================================= */

.form-modal-form-buttons {
  padding: 5px;
}

.form-modal-form-button {
  width: 140px;
  line-height: 40px;
  margin: 5px;
  border-radius: 6px;
  text-align: center;
  font-size: 13px;
  color: var(--neutral-color-1);
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.2s;
  user-select: none;
}

.form-modal-form-button.action {
  background-color: var(--primary-color-b);
}

.form-modal-form-button.action:hover {
  background-color: var(--primary-color-a);
}

.form-modal-form-button.action:active {
  background-color: var(--primary-color-c);
}

.form-modal-form-button.cancel {
  background-color: var(--secondary-color-d);
}

.form-modal-form-button.cancel:hover {
  background-color: var(--secondary-color-c);
}

.form-modal-form-button.cancel:active {
  background-color: var(--secondary-color-e);
}

.form-modal-form-button.close {
  background-color: var(--secondary-color-d);
}

.form-modal-form-button.close:hover {
  background-color: var(--secondary-color-c);
}

.form-modal-form-button.close:active {
  background-color: var(--secondary-color-e);
}

.imageupload :hover {
  z-index: -2;
  position: "relative";
}

.imageupload {
  z-index: -1;
  position: "relative";
}
